import {
  EditOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { useGlobalContext } from "../../../../../src/context/globalContext";
import {
  capitalizeWords,
  generateUniqueId,
} from "../../../../../src/shared/utils/core";
import { Modal, Row, Spin } from "antd";
import { getVideoPreview } from "polotno/utils/video";
import { useEffect, useState } from "react";
import { PageOperationsRow } from "./pageOperationsRow";
import { VideoPanel } from "../../../../../src/features/polotno/component/customPolotno/customVideoSearchPanel";
import { NODE_BACKEND_URL } from "../../../../../src/config";
import axios from "axios";
import { handleEmptyPage } from "@src/shared/utils/polotno/pageOperations";
import VideoTrimmer from "@src/features/polotno/videoTrimmer/videoTrimmer";
interface Props {
  store: any;
  pageID: any;
  index: number;
  page: any;
}

const fetchData = async (
  store: any,
  pageID: any,
  setVisualOverlay: any,
  setPreview: any,
  setVideoUrl: any
) => {
  try {
    const children = store.pages.filter((page: any) => page.id === pageID)[0]
      ?.children;
    const videoElements = children?.filter(
      (child: any) => child.type === "video"
    );
    if (videoElements?.length > 0) {
      const largestVideo = videoElements.reduce(
        (prev: any, current: any) =>
          prev.width * prev.height > current.width * current.height
            ? prev
            : current,
        videoElements[0]
      );

      // Set visual overlay
      setVisualOverlay(largestVideo);

      // Attempt to set video preview
      let videoPreview = "";
      while (!videoPreview) {
        try {
          videoPreview = await getVideoPreview(
            largestVideo.src ||
              "https://media.gettyimages.com/id/1912950930/video/morning-woman-lying-asleep-in-sunlight-closeup-peaceful-girl-waking-up-in-bed.mp4?b=1&s=mp4-640x640-gi&k=20&c=QZuF1gipdYSotcGIAZqLzTgZyGdj1Ih6a-snFWGDuAg="
          );
          setVideoUrl(largestVideo.src);
          setPreview(videoPreview);
        } catch (error) {
          console.error("Error loading video preview:", error);
          // Optionally, set a default preview here if necessary
        }
      }
    } else {
      const imageElements = children?.filter(
        (child: any) => child.type === "image"
      );
      const largestImage = imageElements?.reduce(
        (prev: any, current: any) =>
          prev.width * prev.height > current.width * current.height
            ? prev
            : current,
        imageElements[0]
      );

      // Set visual overlay
      setVisualOverlay(largestImage);

      // Set image preview
      setPreview(
        largestImage?.src ||
          "https://media.gettyimages.com/id/1284856826/photo/happy-woman-stretching-in-bed-after-waking-up-happy-young-girl-greets-good-day.jpg?b=1&s=612x612&w=0&k=20&c=jwLCY3oP6vnT6pcA2USh-ODtbVXz1V-cMVt7b1fGnrI="
      );
    }
  } catch (e) {
    console.log(e);

    // Optionally, handle any other errors here
  }
};

export const VisualOverlayRow = ({ store, pageID, index, page }: Props) => {
  const [preview, setPreview] = useState<string>("");
  const [visualOverlay, setVisualOverlay] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [visible, setVisible] = useState(false);
  const [searchQueries, setSearchQueries] = useState<any>([]);
  const [similarKeywordFetched, setSimilarKeywordsFetched] = useState(false);
  const [pageduration, setPageDuration] = useState(page?.duration || 2000);
  const [videoUrl, setVideoUrl] = useState("");
  const {
    UserDetail: [userDetails, setUserDetails],
    ScriptDetails: [scriptDetails, setScriptDetails],
    UndoStack: [undoStack, setUndoStack],
    RedoStack: [redoStack, setRedoStack],
    VideoStyle: [videoStyle],
    IsStoreUpdated: [isStoreUpdated, setIsStoreUpdated],
  } = useGlobalContext();
  useEffect(() => {
    if (!visible) {
      console.log(visible);
      fetchData(store, pageID, setVisualOverlay, setPreview, setVideoUrl);
      const searchQuery = store?.pages[index]?.custom?.searchQuery;
      const searchQueries = store?.pages[index]?.custom?.searchQueries;
      if (searchQuery)
        setSearchQuery(capitalizeWords(store.pages[index].custom.searchQuery));
      if (searchQueries) setSearchQueries(searchQueries);
    }
  }, [visible]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData(store, pageID, setVisualOverlay, setPreview, setVideoUrl);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const pageDurationIncrementDecrement = (type: string) => {
    if (type === "Increment") {
      const currentPage = store.getElementById(pageID);
      currentPage.set({
        duration: currentPage.duration + 100,
      });
      setPageDuration(currentPage.duration);
    } else if (type === "Decrement") {
      if (pageduration > 500) {
        const currentPage = store.getElementById(pageID);
        currentPage.set({
          duration: currentPage.duration - 100,
        });
        setPageDuration(currentPage.duration);

        console.log(currentPage.duration);
      }
    }
  };
  // useEffect(()=>{
  //   async function fetchSimilarKeyword(){
  //     console.log(store?.pages[index]?.custom?.searchQuery)
  //     const localEndPoint = axios.create({
  //       baseURL: `${NODE_BACKEND_URL}`, // replace with your actual API base URL
  //     });
  //     try{
  //       const response = await localEndPoint.post('/api/v1/openAi/similar-queries', {
  //         OriginalQuery:store?.pages[index]?.custom?.searchQuery
  //       })
  //       const data=response.data
  //       console.log(data)
  //       if(data.length>0){
  //         setSearchQueries(data)
  //       }
  //       setSimilarKeywordsFetched(true)

  //     }catch(error:any){
  //       console.log(error.message)
  //     }
  //   }
  //   if (!visible) {
  //     fetchSimilarKeyword()
  //   }
  // },[visible])

  const openFootageModal = async () => {
    // console.log('--hello')
    setVisible(true);
  };

  useEffect(() => {
    console.log(store.toJSON())
    const emptypage = store.pages.filter(
      (page: any) => page.children.length < 1
    );
    if (emptypage.length > 0) {
      const type = userDetails?.purpose?.mediaType;
      const mediaId = generateUniqueId();
      emptypage[0].addElement({
        type: type == "Videos" ? "video" : "image",
        src:
          type == "Videos"
            ? "https://media.gettyimages.com/id/1313483621/video/footage-of-man-using-hand-sanitizer-over-green-background.mp4?b=1&s=mp4-640x640-gi&k=20&c=Ez5_ks5ahK2bnJP0B-dxBZAinuSJl5sBybx-ZGgfFUA="
            : "https://media.gettyimages.com/id/1284856826/photo/happy-woman-stretching-in-bed-after-waking-up-happy-young-girl-greets-good-day.jpg?b=1&s=612x612&w=0&k=20&c=jwLCY3oP6vnT6pcA2USh-ODtbVXz1V-cMVt7b1fGnrI=",
        x: -25,
        y: -25,
        width: store.width + 50,
        height: store.height + 50,
        id: mediaId,
        custom: { gettyId: "", voiceOver: "" },
      });
      handleEmptyPage(
        store,
        index,
        scriptDetails,
        setScriptDetails,
        setUndoStack,
        setRedoStack,
        videoStyle,
        isStoreUpdated,
        setIsStoreUpdated,
        emptypage[0]
      );
    }
  }, []);
  return (
    <Row gutter={[0, 8]} style={{ flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontFamily: "DM Sans",
            fontSize: "14px",
            flexDirection: "row",
          }}
        >
          <span style={{ maxWidth: "70%" }}>
            {searchQuery
              ? "Scene - " + (index + 1) + " : " + searchQuery
              : "Scene - " + (index + 1)}
          </span>

          <EditOutlined
            style={{
              color: "var(--color-primary-600)",
              marginLeft: "8px",
              marginRight: "5px",
              fontSize: "16px",
            }}
            title="Change Photo"
            onClick={() => openFootageModal()}
          />
          {videoUrl && (
            <div style={{ marginLeft: "8px" }}>
              <VideoTrimmer videoUrl={videoUrl} store={store} pageId={pageID} setPageDuration={setPageDuration} />
            </div>
          )}
        </div>
        <span style={{ fontFamily: "DM Sans", fontSize: "12px" }}>
          {(pageduration/ 1000).toFixed(1)} sec
        </span>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <CaretUpOutlined
            style={{ cursor: "default", outline: "none" }}
            onClick={() => pageDurationIncrementDecrement("Increment")}
          />
          <CaretDownOutlined
            style={{
              cursor: pageduration <= 500 ? "no-drop" : "default",
              outline: "none",
            }}
            onClick={() => pageDurationIncrementDecrement("Decrement")}
          />
        </div>
      </div>
      {preview ? (
        <>
          <div style={{ position: "relative" }}>
            <img
              src={preview}
              alt=""
              style={{
                width: "100%",
                display: "block",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            />
            <div
              style={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}
            >
              <PageOperationsRow store={store} index={index} />
            </div>
          </div>
          <SelectImageModal
            visible={visible}
            setVisible={setVisible}
            store={store}
            visualOverlayID={visualOverlay?.id}
            index={index}
            searchQueries={searchQueries}
            searchQuery={searchQuery}
          />
        </>
      ) : (
        <div style={{ display: "flex", alignContent: "center" }}>
          <Spin />
        </div>
      )}
    </Row>
  );
};

interface ModalProps {
  store: any;
  visualOverlayID: any;
  visible: boolean;
  setVisible: any;
  index: number;
  searchQueries: string[];
  searchQuery: string;
}

const SelectImageModal = ({
  visible,
  setVisible,
  store,
  visualOverlayID,
  index,
  searchQueries,
  searchQuery,
}: ModalProps) => {
  return (
    <Modal
      title="Change Media"
      open={visible}
      onOk={() => {
        setVisible(false);
        store.openSidePanel("video-timeline");
      }}
      onCancel={() => {
        setVisible(false);
        store.openSidePanel("video-timeline");
      }}
      destroyOnClose={true}
    >
      <VideoPanel
        store={store}
        elementID={visualOverlayID}
        index={index}
        isNotPanel={true}
        searchQueries={searchQueries}
        // displaysearchQuery={searchQuery}
      />
    </Modal>
  );
};
interface ModalProps {
  store: any;
}
