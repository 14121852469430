import axios from 'axios'
import { IS_FREE_PLATFORM, NODE_ENV, QUICKADS_REDIRECT_URL, REDIRECT_ON } from '.'

export const QA_BACKEND_URL = process.env.REACT_APP_QA_BACKEND_URL as string
const POLOTNO_BACKEND_URL = process.env.REACT_APP_POLOTNO_BACKEND_URL as string

export const polotnoBackendApiInstance = axios.create({
  baseURL: POLOTNO_BACKEND_URL,
  timeout: 10000,
})

export const qaBackendApiInstance = axios.create({
  baseURL: QA_BACKEND_URL,
  timeout: 1000000000,
})


export const domainLogout = async () => {
  const domainName = window.location.hostname === 'localhost' ? 'dev-aivideos.quickads.ai' : window.location.hostname
  const BE_HANDLE = NODE_ENV === 'dev' ? QA_BACKEND_URL : `${QA_BACKEND_URL}/`
  const response = await axios.get(`${BE_HANDLE}domain/get-domain-names?domain_name=${domainName}&domain_type=video`)
  if (response.status === 200) {
    window.location.href = `https://${response?.data?.domain_name}/logout`
  } else {
    console.error('Invalid URL.')
  }
}

export function getToken() {
  const token = localStorage.getItem('accessToken')
  const cookie = decodeURIComponent(
    document.cookie
      .split('; ')
      .find((row) => row.startsWith('crossToken'))
      ?.split('=')[1] || ''
  )
  const cookieToken = cookie
    ? JSON.parse(
        decodeURIComponent(
          document.cookie
            .split('; ')
            .find((row) => row.startsWith('crossToken'))
            ?.split('=')[1] || ''
        )
      ).token
    : null

  if (token && cookieToken) {
    if (token && cookieToken === token) {
      return token
    } else {
      localStorage.setItem('accessToken', cookieToken)
      return cookieToken
    }
  } else if (token) {
    return token
  } else if (cookieToken) {
    localStorage.setItem('accessToken', cookieToken)
    return cookieToken
  } else {
    localStorage.removeItem('accessToken')
    if (!REDIRECT_ON || IS_FREE_PLATFORM === 'false') domainLogout()
    // window.location.href = QUICKADS_REDIRECT_URL
  }
}
export function getDomainID() {
  const domainID = localStorage.getItem('domainId') as string
  return domainID
}

// export function getDomainID() {
//   const domainID = localStorage.getItem('domainID')
//   const cookie = decodeURIComponent(
//     document.cookie
//       .split('; ')
//       .find((row) => row.startsWith('domainId'))
//       ?.split('=')[1] || ''
//   )
//   const cookieDomainID = cookie ? cookie : null

//   if (domainID && cookieDomainID) {
//     if (domainID && cookieDomainID === domainID) {
//       return domainID
//     } else {
//       localStorage.setItem('domainID', cookieDomainID)
//       return cookieDomainID
//     }
//   }
//   if (domainID) {
//     return domainID
//   }
//   if (cookieDomainID) {
//     localStorage.setItem('domainID', cookieDomainID)
//     return cookieDomainID
//   }
//   if (!domainID) {
//     throw new Error('User not logged in')
//   }
// }

// Use an interceptor to add the token to each request

qaBackendApiInstance.interceptors.request.use(
  async (config) => {
    const token = await getToken()
    // Only set the Authorization header if the token is present and valid
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      // Optionally, handle the case where there is no valid token
      console.warn('User not logged in.')
      domainLogout()
      // if (!REDIRECT_ON || IS_FREE_PLATFORM === 'false') window.location.href = QUICKADS_REDIRECT_URL
      // Depending on your application's flow, you might want to redirect to login here or handle it some other way
    }
    return config
  },
  (error) => {
    // Handle request error here
    console.error('Request configuration error:', error)
    return Promise.reject(error)
  }
)
