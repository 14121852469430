import { useEffect, useState } from "react";
import axios from "axios";

import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import useDeleteAsset from "./useDeleteAsset";
import { NODE_ENV } from '@src/config';
import { getProtectedAxios } from "../auth";
import { getDomainID, QA_BACKEND_URL } from "@src/config/config";

const S3_BUCKET =  NODE_ENV === 'prod' ? "quickads-project" : "dev-quickads-project";
const REGION = "ap-south-1";
const suburl= NODE_ENV === 'prod' ? '/asset/get-asset-video-url' : 'asset/get-asset-video-url'
const s3Client = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: "AKIA3SAF7W5YUUVJTGWU",
    secretAccessKey: "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",
  },
});


// The API call to generate an AI image
async function keyImagesAPI(
  file: File | null,
  domain_id: string
): Promise<any | undefined> {
  if (file) {
    const file_extension = file.name.split(".").pop();
    const postBody = {
      asset_type: "video",
      domain_id: domain_id,
      file_extension: file_extension,
    };

    try {
      const axios = getProtectedAxios();
      const endpoint = QA_BACKEND_URL + suburl;
      return axios.post(endpoint, postBody).then(({ data }) => data);
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
}

// Custom Hook
export function useFileUploadServer() {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { mutate: deleteAsset, isLoading: isAssetDeleting } = useDeleteAsset();


  const handleUpload = async (file: File | null, assert: any): Promise<any> => {
    if (!file) {
      return null;
    }
  
    const params = {
      Bucket: S3_BUCKET,
      Key: assert.key ?? "assertKeyString",
      Body: file,
      ContentType: file.type,
    };
  
    try {
      const command = new PutObjectCommand(params);
      await s3Client.send(command);
      return assert; // Return assert on success
    } catch (uploadError) {
      console.error("Error uploading file:", uploadError);
      setError("Error uploading file");
      deleteAsset(assert?.asset?.id);
      return null; // Return null on failure
    }
  };

  const uploadImage = async (
    file: File | null,
  ) => {
    setLoading(true);
    setError(null);

    try {
      const generatedData = await keyImagesAPI(file,getDomainID()); // Get the File Key
      if (generatedData?.key) {
        const IsFileUploaded = await handleUpload(file, generatedData);

        if (IsFileUploaded) {
          setData(IsFileUploaded);
          return IsFileUploaded?.asset
        }else {
          return null
        }
      } else {
        setError("Failed to upload File");
        return null

      }
    } catch (err) {
      setError("An error occurred");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return { uploadImage, loading,data , error};
}
