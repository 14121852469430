import React, { useEffect, useRef, useState } from 'react';
import {ScissorOutlined } from '@ant-design/icons'
import { Slider, Switch, Button, Popover } from 'antd';
import type { SliderSingleProps } from 'antd/es/slider'; // Adjusting to the correct path for type

// import {getVideoDurationInSeconds } from 'get-video-duration'
// import { Button, Popover } from 'antd';
const VideoTrimmer = ({videoUrl, store, pageId, setPageDuration}:any) => {
  const [videoLength, setVideoLength] = useState<number | null>(null);
  const [disabled, setDisabled] = useState(false);
  const [savedStartTime, setSavedStartTime] = useState(0)
  const [savedEndTime, setSavedEndTime] = useState(0)

  const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value) => `${value} sec`;
  // const [elementId, setElemntId] = useState('')
  const children = store.pages.filter((page: any) => page.id === pageId)[0]?.children
  const videoElements = children?.filter((child: any) => child.type === 'video')
 function normalize(value:number, min:number, max:number) {
  const clampedValue = Math.max(min, Math.min(value, max)); // Clamping the value
  return (clampedValue - min) / (max - min);
}

function denormalize(normalizedValue:any, min:number, max:number) {
  return (normalizedValue * (max - min)) + min;
}

function secondsToMilliseconds(seconds:number) {
  return seconds * 1000;
}

// Example usage:
const seconds = 5;
const milliseconds = secondsToMilliseconds(seconds);
console.log(milliseconds); // Output will be 5000

const videoEl = useRef(null);
  const onChange = (value:any) => {
    if (videoElements?.length > 0) {
      const largestVideo = videoElements.reduce(
        (prev: any, current: any) => (prev.width * prev.height > current.width * current.height ? prev : current),
        videoElements[0]
      )
      const startTime = value[0]
      const endTime = value[1]

      const normalisedStartTime = normalize(startTime, 0, videoLength || 10)
      const normalisedEndTime = normalize(endTime, 0, videoLength || 10)

      // setElemntId(largestVideo.id)
      const element = store.getElementById(largestVideo.id)
      element.set({
      startTime:normalisedStartTime,
      endTime:normalisedEndTime
    })
    const pageDurationInsec = endTime - startTime
    const durationAfterTrimming = secondsToMilliseconds(pageDurationInsec)
    setPageDuration(durationAfterTrimming)

      const page=store.getElementById(pageId)
      page.set({
        duration:durationAfterTrimming
      })
    }

  };
  useEffect(()=>{
    if (videoElements?.length > 0) {
      const largestVideo = videoElements.reduce(
        (prev: any, current: any) => (prev.width * prev.height > current.width * current.height ? prev : current),
        videoElements[0]
      )
     
      const element = store.getElementById(largestVideo.id)
      setSavedStartTime(denormalize(element.startTime, 0, videoLength || 0))
      setSavedEndTime(denormalize(element.endTime, 0, videoLength || 0))
      // setSavedStartTime(element.startTime)
      // setSavedEndTime(element.endTime)
      
    }
  },[videoLength])
  const handleLoadedMetadata = () => {
    const video:any = videoEl.current;
    if (!video) return;
    if (video?.duration) {
      setVideoLength(video.duration)
      console.log(`The video is ${video.duration} seconds long.`);
  }
  };
  return (
    <>
      <Popover content={<Slider step={0.1}  min={0} max={videoLength|| 0} range defaultValue={[savedStartTime || 0, savedEndTime || videoLength|| 0]} disabled={disabled} onChange={onChange} tooltip={{ formatter }}/>} title="Trim Video" trigger="click">
            {/* <Button type="primary">Trim</Button> */}
            <ScissorOutlined />
        </Popover>
        <video src={videoUrl} ref={videoEl} onLoadedMetadata={handleLoadedMetadata} style={{display:'none'}}/>
    </>
  );
};

export default VideoTrimmer;